import jwt_decode from 'jwt-decode';
import { environment } from 'environments/environment';

/**
 * User Token Settings
 * @param token
 */

function setToken(token: string) {
	localStorage.setItem('id_token', token);
}

function getToken() {
	const authCheck = localStorage.getItem('id_token');

	return authCheck || false;
}

function removeToken() {
	// Clear user token data from localStorage
	localStorage.removeItem('id_token');
}

/**
 * User Id settings
 * @param userId
 */
// function setUserId(userId: number) {
//   localStorage.setItem("id_token", userId);
// }

export interface Prop {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
}
function getUserId() {
	//   const userIdCheck = localStorage.getItem("id_token");
	const { id } = jwt_decode(localStorage.getItem('id_token')) as Prop;
	return id;

	//   return userIdCheck || false;
}

function removeUserId() {
	// Clear user token data from localStorage
	localStorage.removeItem('id_token');
}

function getAuthHeaders() {
	const userToken = getToken();
	const options = {
		headers: {
			accept: 'application/json, text/plain, */*',
			authorization: userToken ? `Bearer ${userToken}` : null,
			'Content-Type': 'application/json',
		},
	};

	return options;
}

function getUserEmail() {
	const { email } = jwt_decode(localStorage.getItem('id_token')) as Prop;
	return email;
}

const getAuthOrganizationId = () => {
	return localStorage.getItem('selected_org_id');
};

const handleLogout = () => {
	localStorage.clear();

	if (!environment.LOGOUT_REDIRECT_URL) {
		console.error("Logout URL is not set");
		return;
	}

	const logoutUrl = new URL(environment.LOGOUT_REDIRECT_URL);
	logoutUrl.searchParams.set('state', window.location.toString());

	window.location.assign(logoutUrl.toString());
};

export const auth = {
	setToken,
	getToken,
	removeToken,
	getUserId,
	removeUserId,
	getAuthHeaders,
	getUserEmail,
	getAuthOrganizationId,
	handleLogout,
};
