import { CreateProductsDto, Subcategory } from './../../types/product';
import { network } from './../network';
import { environment } from '../../../../environments/environment';
import { CreateRentalProductDto, GetProductsByTypeQuery } from '../../types/rentalProduct';
import { CreateProductDto } from 'app/react/types/product';
import {
	DEFAULT_PAGE,
	GetProductsByTypeQueryDto,
	IResourcesAvailability,
	PaginationQuery,
	PaginationResultDto,
	ProductDto,
	ProductTypesEnum,
	ResourceNameTypeEnum,
	SimpleProductDto,
	ResourcesForProductQueryDto,
	CreateRentalProductPackageDto,
	ActivityEnum,
} from '@bondsports/types';
import { buildQueryString } from '../../lib/urlUtils';
import { IBasicError } from '@app/react/types/errors';

const productAPIUrl = `${environment.CS_URLS.API_ROOT_V4}/product-pricing`;
const ITEMS_PER_PAGE_DEFAULT = 12;

async function createProducts(organizationId: number, createProductsData: CreateProductsDto): Promise<any> {
	createProductsData.products = createProductsData.products.map(p => convertProductDataToProductDtc(p));

	const response = await network.post(
		`${productAPIUrl}/organization/${organizationId}/products`,
		createProductsData
		//headers
	);

	return response;
}

function convertProductDataToProductDtc(product: CreateProductDto) {
	const createProductDto: CreateProductDto = {
		...product,
	} as CreateProductDto;

	delete (createProductDto as any).productVariants;

	return createProductDto;
}

async function getResources(
	productId: number,
	queryParams?: ResourcesForProductQueryDto
): Promise<IResourcesAvailability | IBasicError> {
	const queryString = buildQueryString({ startDate: queryParams?.startDate, endDate: queryParams?.endDate });

	//Bacause the dafault in he API is true
	const includeResourcesParam = queryParams?.includeResources ? 'includeResources=true' : 'includeResources=false';
	const allQueryParams = queryString ? `${queryString}&${includeResourcesParam}` : `?${includeResourcesParam}`;

	const response = await network.get(`${productAPIUrl}/product/${productId}/resources${allQueryParams}`);
	return response;
}

async function getAddonsData(productId: number): Promise<any> {
	return network.get(`${productAPIUrl}/product/${productId}/package`);
}

async function getSessionBySeason(seasonId: number): Promise<any> {
	const response = await network.get(`${environment.CS_URLS.API_ROOT}/programs/segments/${seasonId}`);
	return response;
}

async function getAllProductsDeprecated(
	organizationId: number,
	page: number,
	includeAddons: boolean,
	categories?: string[],
	search?: string,
	includeAllData?: boolean,
	sports?: string,
	resources?: string
): Promise<any> {
	if (!page) {
		page = 1;
	}
	let productTypes: string[] = [];
	let productSubType: string[] = [];

	if (categories) {
		productTypes = categories?.filter(c => Object.values<string>(ProductTypesEnum).includes(c));
		productSubType = categories?.filter(c => !Object.values<string>(ProductTypesEnum).includes(c));
	}
	const productTypeParam = productTypes?.length > 0 ? `productType=${encodeURIComponent(productTypes.join(','))}&` : ``;
	const productSubTypeParam =
		productSubType?.length > 0 ? `productSubType=${encodeURIComponent(productSubType.join(','))}&` : ``;
	const searchParam = search?.length > 0 ? `search=${encodeURIComponent(search.trim().toLowerCase())}&` : ``;
	const sportsParam = sports ? `sports=${sports}&` : ``;
	const resourcesParams = resources ? `resourceIds=${resources}&` : ``;
	const includeAllDataParam = includeAllData ? `includeAllData=${includeAllData}&` : ``;

	return await network.get(
		`${productAPIUrl}/organization/${organizationId}/paginated-products?${productTypeParam}${productSubTypeParam}${searchParam}${includeAllDataParam}${sportsParam}${resourcesParams}includePrices=true&itemsPerPage=${ITEMS_PER_PAGE_DEFAULT}&page=${page}&${
			includeAddons ? 'includeAddons=true' : ''
		}`
	);
}

async function getSpecificProduct(organizationId: number, productId: string, includeAllData?: boolean): Promise<any> {
	const queryParams = includeAllData !== undefined ? `includeAllData=${includeAllData}` : ``;
	const response = await network.get(
		`${productAPIUrl}/organization/${organizationId}/paginated-products?productIds=${productId}&${queryParams}&includePrices=true&includeAddons=true`
	);
	return response;
}

async function getVariants(organizationId: number): Promise<any> {
	const response = await network.get(`${productAPIUrl}/organization/${organizationId}/variants`);
	return response;
}

async function getAddons(organizationId: number): Promise<any> {
	const response = await network.get(`${productAPIUrl}/organization/${organizationId}/addons`);
	return response;
}

async function getPackages(productId: number): Promise<any> {
	const response = await network.get(`${productAPIUrl}/product/${productId}/package`);
	return response;
}
async function recalcPricing(organizationId: number, data: any): Promise<any> {
	return network.post(`${productAPIUrl}/organization/${organizationId}/products/prices`, data);
}

async function getSubcategories(organizationId: number, stationId?: number): Promise<Subcategory[]> {
	const { data } = await network.get(
		`${productAPIUrl}/organization/${organizationId}/product-subcategories?${stationId ? `stationId=${stationId}` : ''}`
	);

	return data;
}
/**
 * @deprecated
 *
 *
 * This function sends a POST request to create a new rental product using the old API endpoint.
 *
 * @param {number} organizationId - The ID of the organization.
 * @param {CreateRentalProductDto} rentalProductData - The data for the new rental product.
 * @returns {Promise<any>} The response from the API.
 */
const createRentalProductDepricated = async (organizationId: number, rentalProductData: CreateRentalProductDto) => {
	const response = await network.post(`${productAPIUrl}/organization/${organizationId}/products`, {
		products: [rentalProductData],
	});
	return response;
};

async function setArchiveProduct(organizationId: number, productId: number, isArchive = true): Promise<object> {
	const data = { isArchive };
	const response = await network.post(
		`${productAPIUrl}/organization/${organizationId}/product/${productId}/archive`,
		data
	);

	return response;
}

async function updateProductStatus(organizationId: number, productId: number, status: ActivityEnum): Promise<void> {
	await network.put(`${productAPIUrl}/organization/${organizationId}/products`, [{ id: productId, status }]);
}

async function getPaginatedProductsByResource(
	organizationId: number,
	resourceType: ResourceNameTypeEnum,
	resourceId: number,
	pagination: PaginationQuery & { search?: string }
): Promise<PaginationResultDto<SimpleProductDto>> {
	const query: string = buildQueryString(pagination);
	const response = await network.get(
		`${productAPIUrl}/products/organization/${organizationId}/resource/${resourceType}/${resourceId}/simple${query}`
	);

	return response as PaginationResultDto<SimpleProductDto>;
}

const getPaginatedProductsByType = async (
	organizationId: number,
	productType: ProductTypesEnum,
	queryData: GetProductsByTypeQueryDto,
	page: number = DEFAULT_PAGE,
	signal?: AbortSignal
): Promise<PaginationResultDto<ProductDto> | IBasicError> => {
	const query = buildQueryString({
		...queryData,
		itemsPerPage: queryData.itemsPerPage || ITEMS_PER_PAGE_DEFAULT,
		page,
		includeGlCodes: queryData.includeGlCodes ?? true,
		includeResources: queryData.includeResources ?? true,
	});

	return await network.get(`${productAPIUrl}/organization/${organizationId}/products/${productType}${query}`, {
		signal,
	});
};

const getProductById = async (
	organizationId: number,
	productId: number,
	queryData: GetProductsByTypeQuery
): Promise<ProductDto | IBasicError> => {
	const query = buildQueryString(queryData);

	return await network.get(`${productAPIUrl}/organization/${organizationId}/product/${productId}${query}`);
};

const createRentalProduct = async (
	organizationId: number,
	data: CreateRentalProductPackageDto
): Promise<ProductDto | IBasicError> => {
	const response = await network.post(`${productAPIUrl}/organization/${organizationId}/rental-product`, data);
	return response;
};

export const productApi = {
	createProducts,
	createRentalProductDepricated,
	getResources,
	getAllProductsDeprecated,
	getVariants,
	getSessionBySeason,
	getSpecificProduct,
	updateProductStatus,
	getAddons,
	getPackages,
	setArchiveProduct,
	getAddonsData,
	recalcPricing,
	getSubcategories,
	getPaginatedProductsByResource,
	createRentalProduct,
	getPaginatedProductsByType,
	getProductById,
};
