import { format, getDay, getYear } from 'date-fns';
import * as dayjs from 'dayjs';
import * as tz from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ActivityTime } from '../types/season';
import { EDateTimeFormats, EDurations, EDaysShort, EDaysLong, EMonthsShort, ETimeFormats } from '../types/times';
import { MustHaveStartAndEndDates } from '../components/customers/activities/types/types';
import { DateTimeFormats, getStartOfDay } from '@bondsports/date-time';
import { EDateFormats, EHoursFormat } from '@bondsports/utils';

dayjs.extend(utc);
dayjs.extend(tz);

export const MAX_DATE = new Date('275759-09-13');
export const MIN_DATE = new Date('1970-01-01');

export const UTC_TIMEZONE = 'UTC';

export const DAY_START_TIME = '00:00:00';
export const DAY_END_TIME = '23:59:59';

export const timeFormatRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9] (am|pm)$/i;

export const getCurrentDateInSpecificTimezone = (timezone: string) => {
	const dateAndTimeUtc = combineDateTime(
		dayjs().format(EDateFormats.DAY_FORMAT),
		dayjs().format(EDateTimeFormats.H12_ampm)
	);
	return dayjs.utc(dateAndTimeUtc).tz(timezone);
};

/**
 * Check if a given time and date is before the current time in a specified timezone.
 * @param {string} time - The time to compare (in "h:mm A" format).
 * @param {string} date - The date to compare (in "YYYY-MM-DD" format).
 * @param {string} timezone - The timezone to perform the comparison in.
 * @returns {boolean} True if the provided time and date is before the current time in the specified timezone, otherwise false.
 */
export const isBeforeCurrentTime = (time: string, date: string, timezone: string) => {
	if (!time || !timezone) {
		return;
	}

	const currentDateTarget = getCurrentDateInSpecificTimezone(timezone);
	const currentTimeTargetTimezone = currentDateTarget.format(EDateTimeFormats.H24_WITH_SECONDS);
	const dateAndTimeToCheck = combineDateTime(date, time);
	const timeToCheck = dayjs(dateAndTimeToCheck).format(EDateTimeFormats.H24_WITH_SECONDS);
	const dayToCheck = dayjs(dateAndTimeToCheck).format(EDateTimeFormats.DAY_FORMAT);
	const currentDateTargetTimezone = dayjs(currentDateTarget).format(EDateTimeFormats.DAY_FORMAT);
	return (
		dayjs(timeToCheck, EDateTimeFormats.H24_WITH_SECONDS).isBefore(
			dayjs(currentTimeTargetTimezone, EDateTimeFormats.H24_WITH_SECONDS)
		) &&
		dayjs(dayToCheck, EDateTimeFormats.H24_WITH_SECONDS).isSame(
			dayjs(currentDateTargetTimezone, EDateTimeFormats.H24_WITH_SECONDS)
		)
	);
};

export const isBeforeTime = (time: string, before: string) => {
	return time && dayjs(time, ETimeFormats.H12_ampm).isBefore(dayjs(before, ETimeFormats.H12_ampm), EDurations.MINUTES);
};

export const isValidTimeString = (time: string) => {
	return time && dayjs(time, ETimeFormats.H12_ampm).isValid() && timeFormatRegex.test(time);
};

const padTo2Digits = (n: number) => (n > 9 ? String(n) : `0${n}`);

export function getCurrentYear() {
	return getYear(Date.now());
}

export const getEndOfYearFromGivenDate = (
	givenDate: string | Date,
	yearsToAdd = 1,
	format = EDateTimeFormats.YYYY_MM_DD
) => {
	return dayjs(givenDate).add(yearsToAdd, EDurations.YEAR).format(format);
};

export const lastDayOfCurrentMonth = dayjs().endOf(EDurations.MONTH).format(EDateTimeFormats.YYYY_MM_DD);

export const checkWaiverValidation = (date: string | null) => {
	if (date) {
		date = date.slice(0, 11);
	}

	const isBefore = dayjs(date, EDateTimeFormats.YYYY_MM_DD).isBefore(
		dayjs().subtract(1, EDurations.YEAR),
		EDurations.YEAR
	);

	return date && !isBefore ? true : false;
};

/**
 * Get today's day number.
 */
export function getToday() {
	return getDay(Date.now());
}

/**
 * Get the month name by the month number
 *
 * @param month - the month number
 */
export function getMonthName(month: number) {
	if (month) {
		const monthsNames = Object.values(EMonthsShort);
		return monthsNames[month - 1];
	} else {
		return '';
	}
}

// so .. there's a HUGE bug in the API that expects all times passed as if they happened in UTC
// so if something happens in Israel 2021-01-21 at 19:00, you'll notice these:
// "2021-01-21T19:00:00+02:00" --->> breaks the server
// "2021-01-21T19:00:00Z"      --->> makes the server happy
// ALTHOUGH it's wrong - the server's happy about that
export function fixBrokenTZDate(sDateTime: string) {
	const d = new Date(sDateTime);
	const dateStr = `${d.getFullYear()}-${padTo2Digits(d.getMonth() + 1)}-${padTo2Digits(d.getDate())}`;
	const timeStr = `${padTo2Digits(d.getHours())}:${padTo2Digits(d.getMinutes())}:00Z`;

	return `${dateStr}T${timeStr}`;
}

export function UTCFormat(date: string, time: string) {
	const dateString = `${date} ${time}`;
	const dateAndTime = new Date(dateString);
	const utsFormat = format(new Date(dateAndTime), "yyyy-MM-dd'T'HH:mm:ss'Z'");
	return utsFormat;
}
//todo: export and replace in all places
const timeSerialization = (time: string, format: EHoursFormat = EHoursFormat.H24_WITH_SECONDS) => {
	if (time) {
		if (dayjs(time, EHoursFormat.H12_ampm).isValid()) {
			return dayjs(time, EHoursFormat.H12_ampm).format(format);
		}
		return time;
	} else {
		return '';
	}
};
/**
 * Combines a date and time into a single DateTime string.
 * @param {string} date - The date in the format 'YYYY-MM-DD'.
 * @param {string} time - The time in the format 'HH:mm:ss'/'hh:mm a' .
 * @param {string} timezone - The timezone to use for the DateTime string.
 * @returns {string} The combined DateTime string in the format 'YYYY-MM-DDTHH:mm:ss.000Z'.
 */
export function combineDateTime(
	date: string,
	time: string,
	timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string {
	// Separate the hour and minutes to add them individually
	const [hour, minutes] = timeSerialization(time).split(':').map(Number);

	// When offset is negative need to multiply by -1 to add more minutes, otherwise it will subtract.
	const offsetInMinutes: number = dayjs().tz(timezone).utcOffset() * -1;

	const baseDate: Date = getStartOfDay(date, true) as Date;
	const datetime = dayjs(baseDate).utc(true).set(EDurations.HOURS, hour).set(EDurations.MINUTES, minutes);

	return datetime.add(offsetInMinutes, EDurations.MINUTES).toJSON();
}

// subday in /client/programs/camps/243/season/1248/events -> tab schedule press on venue is 1, not 8

export const dayOfWeekMapper = {
	1: EDaysLong.SUNDAY,
	2: EDaysLong.MONDAY,
	3: EDaysLong.TUESDAY,
	4: EDaysLong.WEDNESDAY,
	5: EDaysLong.THURSDAY,
	6: EDaysLong.FRIDAY,
	7: EDaysLong.SATURDAY,
	8: EDaysLong.SUNDAY,
};

export const bondsportsDays = {
	2: EDaysShort.MON,
	3: EDaysShort.TUE,
	4: EDaysShort.WED,
	5: EDaysShort.THU,
	6: EDaysShort.FRI,
	7: EDaysShort.SAT,
	8: EDaysShort.SUN,
};

export const timeTypeOptions = [
	{ value: 1, label: 'Hours' },
	{ value: 2, label: 'Days' },
	{ value: 3, label: 'Months' },
];

// RC stores in some places days 2-8 instead of 0-6
export function getFixedDayName(day: number, short = true) {
	if (day) {
		return short ? dayOfWeekMapper[day].slice(0, 3) : dayOfWeekMapper[day];
	} else {
		return '';
	}
}

/**
 * Get the day name by the day number
 *
 * @param day
 * @param short - boolean if to return first 3 characters
 *                or the full day name
 *
 * @returns string - The day name
 */
export function getDayName(day: number, short = true) {
	if (day || day === 0) {
		const daysNames = Object.values(EDaysLong);
		if (short) {
			return daysNames[day].slice(0, 3);
		}
		return daysNames[day];
	} else {
		return '';
	}
}

export function getActivityOpenCloseHours(open: string, close: string, dayOfWeek: string) {
	if (open && close && dayOfWeek) {
		// This constant date has no significance
		// since we want the time only. You can put
		// any valid date you want.
		const openHour = format(new Date(`2020-12-31T${open}`), 'ha');
		const closeHour = format(new Date(`2020-12-31T${close}`), 'ha');

		// Days range from api is: 2-8 (Mon-Sun)
		return `${getDayName(Number(dayOfWeek) - 2)}, ${openHour} - ${closeHour}`;
	} else {
		return '';
	}
}

export const parseHoursRange = ({ from, to }: { from: string; to: string }) => `${from} - ${to}`;

const UTC_INDICATOR = 'Z';
const DATE_TIME_SEPARATOR = 'T';
const START_DAY = 'T00:00:00';

const getDateWithoutUtcIndicator = (date: string) => {
	return date.slice(0, -1);
};

export function apiDateStringToDate(date: string = ''): Date {
	if (date.endsWith(UTC_INDICATOR)) {
		return new Date(getDateWithoutUtcIndicator(date));
	}

	if (date.includes(DATE_TIME_SEPARATOR)) {
		return new Date(date);
	}

	return new Date(`${date}${START_DAY}`);
}

export function apiDateStringWithoutZ(date: string = ''): string {
	return date.endsWith(UTC_INDICATOR) ? getDateWithoutUtcIndicator(date) : date;
}

/**
 * returns something like Apr 1
 */
export function getDateString(date: Date): string {
	if (date) {
		return `${getMonthName(date.getMonth() + 1)} ${date.getDate()}`;
	} else {
		return '';
	}
}

export function getDateStringWithYear(date: Date): string {
	if (date) {
		return `${getMonthName(date.getMonth() + 1)} ${date.getDate()}, ${date.getFullYear()}`;
	} else {
		return '';
	}
}

export function getDates(date: string) {
	return getDateString(apiDateStringToDate(date));
}

export function getDatesRange(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}

	const end = apiDateStringToDate(endDate || '');
	return `${getDateString(start)} - ${getDateString(end)}`;
}

export function getDatesRangeWithYears(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}

	const end = apiDateStringToDate(endDate || '');
	return `${getDateStringWithYear(start)} - ${getDateStringWithYear(end)}`;
}

export function getDatesRangeWithYearsOnSameYear(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}

	const end = apiDateStringToDate(endDate || '');

	return start.getFullYear() === end.getFullYear()
		? `${getDateString(start)} - ${getDateStringWithYear(end)}`
		: `${getDateStringWithYear(start)} - ${getDateStringWithYear(end)}`;
}

//  return Dec 17 - 20, 2022 OR Dec 17 - Jan 3, 2022
export function getMonthDayRange(startDate: string, endDate?: string) {
	const start = apiDateStringToDate(startDate);
	if (!endDate) {
		return getDateString(start);
	}
	const end = apiDateStringToDate(endDate || '');
	const startString = `${getDateString(start)}`;
	const endString = start.getMonth() === end.getMonth() ? end.getDate() : `${getDateString(end)}`;
	const endStringYear = end.getFullYear();

	return `${startString} - ${endString}, ${endStringYear}`;
}

export function getTimes(startDateWithTime: string, endDateWithTime: string) {
	let [, startTime] = startDateWithTime ? startDateWithTime.split('T') : ['', ''];
	let [, endTime] = endDateWithTime ? endDateWithTime.split('T') : ['', ''];
	startTime = startTime.slice(0, 8);
	endTime = endTime.slice(0, 8);
	return getTimeRangeDisplay(startTime, endTime);
}

export function time24ToAmPm(hour: number, minutes: number): string {
	const amOrPm = hour >= 12 && hour < 24 ? 'PM' : 'AM';

	if (hour > 12) {
		hour = hour - 12;
	}
	if (hour === 0) {
		hour = 12;
	}

	return `${hour}:${padTo2Digits(minutes)}${amOrPm}`;
}

export function getTime24ToAmPmDisplay(time: string): string {
	const [hour, minutes] = time.split(':');
	return time24ToAmPm(Number(hour), Number(minutes));
}

export function convertTimeAmPmTo24(AmPmTime: string): string {
	const [time, status] = AmPmTime.split(' '); //better name then status, AmPmTime???
	return '';
}

export function getTimeRangeDisplay(startTime: string, endTime: string) {
	return `${getTime24ToAmPmDisplay(startTime)} - ${getTime24ToAmPmDisplay(endTime)}`;
}

// export function getDateTimeByActivity(activityTime: ActivityTime) {
//   return getTimeRangeDisplay(activityTime.open, activityTime.close);
// }

export const getDayMonth = (date: string) => {
	const d = apiDateStringToDate(date);
	return `${getMonthName(d.getMonth() + 1)} ${d.getDate()}`;
};

export const getDateYear = (date: string) => {
	const dateYear = apiDateStringToDate(date);
	return ` ${dateYear.getFullYear()}`;
};

// converts an array of numbers into ranges
// e.g, [0,1,2,4,5] => [[0,2],[4,5]]
export function getRanges(array: number[]) {
	const ranges: number[][] = [];
	for (let i = 0; i < array.length; i++) {
		const rstart = array[i];
		let rend = rstart;
		while (array[i + 1] - array[i] === 1) {
			rend = array[i + 1]; // increment the index if the numbers sequential
			i++;
		}
		ranges.push(rstart === rend ? [rstart] : [rstart, rend]);
	}
	return ranges;
}

/**
 * @description returns the number of years between two dates
 * @param past the past date
 * @param from the date to compare to, defaults to today
 * @returns the number of years between the two dates
 *
 * @example
 * getYearsDiff('2019-01-01') // 2
 * getYearsDiff('2019-01-01', '2020-01-01') // 1
 * getYearsDiff('2019-01-01', '2019-01-01') // 0
 * getYearsDiff('2019-01-01', '2018-01-01') // -1
 */
export function getYearsDiff(past: string, from?: string | dayjs.Dayjs): number {
	if (!from) {
		from = dayjs();
	}

	return dayjs(from).diff(dayjs(past), EDurations.YEAR);
}

export function getDaysAndTimeForActivityTimes(activityTimes: ActivityTime[]): string[] {
	// create a map of key: startTime-endTime => value [ days ]
	const byTime = activityTimes.reduce((acc: { [key: string]: ActivityTime[] }, v) => {
		const times = getTimeRangeDisplay(v.open, v.close);
		if (!acc[times]) {
			acc[times] = [];
		}
		acc[times].push(v);
		return acc;
	}, {});

	const daysAndTimes: string[] = [];
	for (const time in byTime) {
		const days = byTime[time].map(activity => Number(activity.dayOfWeek)).sort();
		// we get ranges either of 2 days (such as monday to thursday) == [2,5]
		// or 1 day (meaning no range): monday [2]
		const daysRange = getRanges(days).reduce((acc, range) => {
			acc += acc ? ',' : '';
			acc += getFixedDayName(range[0]);
			if (range.length === 2) {
				acc += `-${getFixedDayName(range[1])}`;
			}
			return acc;
		}, '');
		daysAndTimes.push(`${daysRange} ${time}`);
	}
	return daysAndTimes;
}

export function getDaysAndTimesForActivityTimesDaysSeparately(activityTimes: ActivityTime[]): string[] {
	const daysAndTimes = activityTimes.map(activity => {
		const dayNumber = Number(activity.dayOfWeek);
		const currentDay = getFixedDayName(dayNumber);

		return `${currentDay}, ${getTimeRangeDisplay(activity.open, activity.close)}`;
	});

	return daysAndTimes;
}

export const getProductAvailabilityStrings = (availabiltyTimeData: ActivityTime[]) => {
	// create a map of key: startTime-endTime => value [ days ]
	const weekDaysByTimes = availabiltyTimeData?.reduce((acc: { [key: string]: Set<number> }, v) => {
		const times = `${dayjs(v.open, 'HH:mm:ss').format('h A')} - ${dayjs(v.close, 'HH:mm:ss').format('h A')}`;
		if (!acc[times]) {
			acc[times] = new Set<number>();
		}
		acc[times].add(Number(v.dayOfWeek));
		return acc;
	}, {});
	const availabilityStrings = [];
	if (weekDaysByTimes) {
		for (const [key, value] of Object.entries(weekDaysByTimes)) {
			const arrayFromDaysSet = Array.from(value);
			let dayString = '';
			const sortedDays = arrayFromDaysSet.sort((a, b) => a - b);
			if (
				sortedDays.every((element, index, array) =>
					index + 1 < array.length ? element + 1 === array[index + 1] : true
				)
			) {
				dayString = `${bondsportsDays[sortedDays[0]]} - ${bondsportsDays[sortedDays[sortedDays.length - 1]]}`;
			} else {
				dayString = arrayFromDaysSet.map(dayOfWeek => bondsportsDays[dayOfWeek]).join(', ');
			}
			availabilityStrings.push(` ${dayString} | ${key}`);
		}
	}
	return availabilityStrings;
};

// get date from iso date string
export const getDateFromISO = (date: string): string | null => date?.split('T')?.[0] || null;

type TDateFormats = typeof EDateTimeFormats.YYYY_MM_DD;

//Todo: use only DateTimeFormats(remove EDateTimeFormats and fix allusages)
export const formatDate = (date: string | Date, format: EDateTimeFormats | DateTimeFormats, timezone?: string) => {
	if (!dayjs(date).isValid()) {
		return date as string;
	}
	if (timezone) {
		return dayjs(date).tz(timezone).format(format);
	}
	return dayjs(date).format(format);
};

export const formatStartAndEnd = (startDate: string, endDate: string) => {
	const formattedStartDate = formatDate(startDate, EDateTimeFormats.YYYY_MM_DD);
	const formattedEndDate = formatDate(endDate, EDateTimeFormats.YYYY_MM_DD);
	return { formattedStartDate, formattedEndDate };
};

export const isDateInRange = (date: string, endDate: string) => {
	return dayjs(date).isBefore(dayjs(endDate));
};

export const formatToDayAndTime = (startDate: string, startTime: string) => {
	return dayjs(`${startDate} ${startTime}`).format(EDateTimeFormats.DAY_OF_WEEK_AND_1H12_AMPM);
};

export function getEarlierDate(pre: Date | string | number, cur: Date | string | number): Date {
	const date = dayjs(pre).isBefore(cur) ? pre : cur;

	return date instanceof Date ? date : dayjs(date).toDate();
}

export function getTheEarliestDate(dates: Date[]) {
	return dates.reduce(getEarlierDate, MAX_DATE);
}

export function isEarlier(date: Date | string | number, other: Date | string | number): boolean {
	const earliestDate: Date = getEarlierDate(date, other);
	return dayjs(date).isSame(earliestDate);
}

export function isSameDate(date: Date, other: Date): boolean {
	return dayjs(date).isSame(other, 'date');
}

export function setTimezone(date: Date, timezone: string): Date {
	return dayjs(date).tz(timezone).toDate();
}

export function add(date: Date, value: number, unit: dayjs.ManipulateType): Date {
	return dayjs(date).add(value, unit).toDate();
}

export function sortByStartDate<T extends MustHaveStartAndEndDates>(items: T[]): T[] {
	return [...items].sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)));
}
export function subtract(date: Date, value: number, unit: dayjs.ManipulateType): Date {
	return dayjs(date).subtract(value, unit).toDate();
}

export function dateToUTCWithoutConversion(date: Date | string | number): string {
	if (!dayjs(date).isValid()) {
		return date as string;
	}
	return dayjs.utc(dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS')).toString();
}

export function getEndOfDay(date: Date | string | number): Date {
	return dayjs(date).endOf('day').toDate();
}

export function setUTCTimezone(date: Date | string | number): Date {
	return dayjs(date).utc(false).toDate();
}

export function getEPOCNumber(date: Date | string | number): number {
	return Number(dayjs(date));
}

export interface FormattedHeader {
	dayOfWeek: string;
	fullDate: string;
	formattedStartTime: string;
	formattedEndTime: string;
}

export function getDateAndTimeHeader(
	startDate: Date,
	endDate: Date,
	startTime: string,
	endTime: string
): FormattedHeader {
	const dayOfWeek = dayjs(startDate).format(EDateTimeFormats.DAY); // Get the day (e.g., "Tue")
	const fullDate = dayjs(startDate).format(EDateTimeFormats.DATE_FORMAT_WITH_SINGLE_DIGIT_DAY); // Format the rest of the date (e.g., "Nov 28, 2023")

	const formattedStartTime = dayjs(startTime, ETimeFormats.H24_WITH_SECONDS).format(ETimeFormats.H12_AMPM_UPPERCASE); // Format start time with AM/PM (e.g., "10:00 AM")
	const formattedEndTime = dayjs(endTime, ETimeFormats.H24_WITH_SECONDS).format(ETimeFormats.H12_AMPM_UPPERCASE); // Format end time with AM/PM (e.g., "11:00 AM")
	// Build the desired string
	return { dayOfWeek, fullDate, formattedStartTime, formattedEndTime };
}

export const timeRangeTemplate = ({
	startTime,
	endTime,
	timeFormat1,
	timeFormat2 = timeFormat1,
}: {
	startTime: Date;
	endTime: Date;
	timeFormat1: EDateTimeFormats;
	timeFormat2?: EDateTimeFormats;
}) => {
	return `${dayjs(startTime).format(timeFormat1)} - ${dayjs(endTime).format(timeFormat2)}`;
};

export function getEpochTime(date: Date | number | string): number {
	return dayjs(date).unix();
}

export function formatDateRange(date1: Date, date2: Date, format: EDateTimeFormats, timezone?: string): string {
	timezone ??= Intl.DateTimeFormat().resolvedOptions().timeZoneName;
	//
	return `${formatDate(setTimezone(date1, timezone), format)} - ${formatDate(setTimezone(date2, timezone), format)}`;
}

export function formatTime(time: string, format: ETimeFormats): string {
	const date = `1970-01-01T${time}`;
	return dayjs(date).format(format);
}

export function dateComparator(extractor: (any) => string) {
	return (a, b) => Date.parse(extractor(a)) - Date.parse(extractor(b));
}

export function convertToDateStringWithFormat(
	date: Date,
	timezone?: string,
	format: DateTimeFormats | EDateTimeFormats = EDateTimeFormats.YYYY_MM_DD
): string {
	return timezone ? dayjs(date).tz(timezone).format(format) : dayjs(date).format(format);
}

export function createDateFromTimeString(timeString) {
	const [hours, minutes, seconds = 0, milliseconds = 0] = timeString.split(':').map(Number);

	// Create a new Date object with the current date
	const currentDate = new Date();

	// Set the time components
	currentDate.setHours(hours);
	currentDate.setMinutes(minutes);
	currentDate.setSeconds(seconds);
	currentDate.setMilliseconds(milliseconds);

	return currentDate;
}

export function formatTimeToAmPm(dateTime: string) {
	return dayjs(dateTime, EHoursFormat.H24_WITH_SECONDS).isValid()
		? dayjs(dateTime, EHoursFormat.H24_WITH_SECONDS).format(EHoursFormat.H12_ampm)
		: dateTime;
}

export function checkIfDateReached(
	startDateString: string,
	timezone = Intl.DateTimeFormat().resolvedOptions().timeZoneName
) {
	const currentDate = dayjs().tz(timezone);
	const startDate = dayjs(startDateString).tz(timezone);
	return currentDate > startDate;
}

export const isDateToday = (date: string) => {
	return dayjs(date).isSame(dayjs(), EDurations.DAY);
};

export function getPreviousDay(date: Date, format = EDateTimeFormats.YYYY_MM_DD): string {
	return formatDate(subtract(setUTCTimezone(date), 1, 'day'), format);
}

export function getNextDay(date: Date, format = EDateTimeFormats.YYYY_MM_DD): string {
	return formatDate(add(setUTCTimezone(date), 1, 'day'), format);
}

export const toDate = (date: string, timezone: string) => {
	const dateInTimezone = dayjs(date).tz(timezone);
	return new Date(
		dateInTimezone.year(),
		dateInTimezone.month(),
		dateInTimezone.date(),
		dateInTimezone.hour(),
		dateInTimezone.minute(),
		dateInTimezone.second(),
		dateInTimezone.millisecond()
	);
};

export function getCurrentDate() {
	return dayjs().format(DateTimeFormats.YYYY_MM_DD);
}

export const formatDateRangeToISOString = (startDate: string | Date, endDate: string | Date, timezone: string) => {
	return {
		startDate: dayjs(startDate).tz(timezone, true).startOf(EDurations.DAY).toISOString(),
		endDate: dayjs(endDate).tz(timezone, true).endOf(EDurations.DAY).toISOString(),
	};
};

export const createDefaultDateRange = (sessionStartDate: Date, sessionEndDate: Date, timezone: string) => {
	const now: Date = new Date();
	const startDate: Date = dayjs(sessionStartDate).utc().isBefore(now) ? now : sessionStartDate;
	const endDate: Date = dayjs(sessionEndDate).utc().isAfter(now) ? sessionEndDate : now;
	return formatDateRangeToISOString(startDate, endDate, timezone);
};

export const formatOpeningDates = (day: string | number, month: string | number) => {
	return `${day}/${month}`;
};

export function convertDateTimeStringToDate(date: string, time: string): Date {
	return dayjs(`${date} ${time}`).toDate();
}

/**
 * Creates an array of time slots with differences from a start time.
 *
 * @param {number} durationInMinutes - The duration of each time slot in minutes.
 * @param {dayjs.OpUnitType} [differenceUnit='minutes'] - The unit of time for the difference calculation.
 * @param {string} [startTime] - The start time to calculate differences from.
 * @returns {{ time: string; difference?: number; isBeforeOrSame: boolean }[]} An array of objects containing time slots, differences, and a flag indicating if the time is before or the same as the start time.
 */
export function createTimesWithDifference(
	durationInMinutes: number,
	differenceUnit: dayjs.OpUnitType = 'minutes',
	startTime?: string
): { time: string; difference?: number; isBeforeOrSame: boolean }[] {
	// 1440 minutes in a day
	const parts = 1440 / durationInMinutes;

	const times = [];

	const startOfDay: dayjs.Dayjs = dayjs(getStartOfDay(new Date()));
	const startTimeValue: Date = startTime
		? convertDateTimeStringToDate(startOfDay.format(EDateTimeFormats.YYYY_MM_DD), startTime)
		: null;

	for (let i = 0; i <= parts; i++) {
		const datetime = startOfDay.add(i * durationInMinutes, 'minute');
		const isBeforeOrSame = startTimeValue
			? datetime.isBefore(startTimeValue) || datetime.isSame(startTimeValue)
			: false;
		const difference = startTimeValue ? datetime.diff(startTimeValue, differenceUnit) : undefined;

		times.push({
			time: datetime.format(ETimeFormats.H24_WITH_MINUTES),
			difference,
			isBeforeOrSame,
		});
	}

	return times;
}

/**
 * Adds a specified amount of time to a given time.
 * @param time {string} - The time to add to (formant hh:mm:ss).
 * @param unit {dayjs.ManipulateType} - The unit of time to add.
 * @param amount {number} - The amount of time to add.
 * @param format {ETimeFormats} - The format of the time defaults to hh:mm.
 * @returns {string} - The new time with the provided format.
 */
export function addToTime(
	time: string,
	unit: dayjs.ManipulateType,
	amount: number,
	format = ETimeFormats.H24_WITH_MINUTES
): string {
	const startOfDay: dayjs.Dayjs = dayjs(getStartOfDay(new Date()));
	const datetime = convertDateTimeStringToDate(startOfDay.format(EDateTimeFormats.YYYY_MM_DD), time);
	return dayjs(datetime).add(amount, unit).format(format);
}
